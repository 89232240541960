import {
  BodyView,
  HomeTitleView,
  HomeDescriptionView,
  HomeImageSection,
  HomeHiestoryView,
  HomeIEYCTextView,
  HomeAdmissionInformationView,
  HomeAdmissionInformationTextView,
  HomeAdmissionInformationDescriptionView,
  HomeAdmissionInformationButtonView,
  HomeAdmissionInfomationImageView,
  HomeFamilyInformationView,
  HomeFamilyInfoVeriants,
  HomeFamilyInfoVeriants2,
  HomeFamilyInfoImageView,
  HomeFamilyInfoImageView2,
  HomeFamilyInfoTextView,
  handleOpenNewTab,
  handleLink,
  PathStringView,
  HomeImageZone,
  HomeFamilyInfoTextView2,
  HomeDescriptionKorView,
  HomeIEYCKorTextView,
  HomeIEYCKorTextSpan,
  HomeIEYCTextSpan,
} from "./common";

import Banner, { BannerTablet } from "../Componets/Banner";
import { Desktop, Mobile, Tablet } from "../Device";
import { useState } from "react";
import Popup, { PopupImageButton2 } from "../Componets/Popup";
import { StringManager } from "../assets/StringManager";
import { useScreenType } from "../Componets/Devicets";

function Home() {
  // const [loading, setLoading] = useState(true);
  const [openPopup, setOpenPopup] = useState(true);

  return (
    <div>
      <>
        {openPopup && (
          <PopupImageButton2
            closePopup={() => setOpenPopup(false)}
            clicklink1={"https://forms.gle/zagVyeQBYJQzDJPu6"}
            clicklink2={"https://forms.gle/Wh4KCEA2JMAiLx919"}
            imglink={
              "https://playsongshome.playsongs.ai/popup/25-26-900-900.jpg"
            }
          />
        )}
      </>

      <HomeView />
    </div>
  );
}

function HomeView() {
  return (
    <>
      <Mobile>
        <MobileTabletHomeView />
      </Mobile>
      <Tablet>
        <MobileTabletHomeView />
      </Tablet>
      <Desktop>
        <DesktopHome />
      </Desktop>
    </>
  );
}
function MobileTabletHomeView() {
  const screenType = useScreenType();
  return (
    <>
      <BodyView>
        <BannerTablet />
        <HomeTitleView className="text_wrap">
          {StringManager.homeTitleString[screenType]}
        </HomeTitleView>
        <DescriptionView />
        <ImageSectionView />
        <AdmissionView />
        <TabletMobileStoreBanner />
      </BodyView>
    </>
  );
}

function DesktopHome() {
  const screenType = useScreenType();
  return (
    <BodyView className="text_wrap">
      <PathStringView className="path" />
      <Banner />
      <HomeTitleView className="text_wrap">
        {StringManager.homeTitleString[screenType]}
      </HomeTitleView>
      <DescriptionView />
      <ImageSectionView />
      <AdmissionView />
      <StoreBanner />
    </BodyView>
  );
}

function TabletMobileStoreBanner() {
  return (
    <>
      <HomeFamilyInfoVeriants
        onClick={() => handleOpenNewTab("http://www.playsongs.co.kr")}
      >
        <HomeFamilyInfoImageView>
          <img
            src="https://playsongshome.playsongs.ai/charlie/home/tablet/intro_playsongs.png"
            alt="character"
            width={"100%"}
          />
        </HomeFamilyInfoImageView>
        <HomeFamilyInfoTextView className="text_wrap">
          <Tablet>찰리와 함께하면{"\n"}더 좋은 플레이송스를 만나보세요</Tablet>
          <Mobile>
            찰리와 함께하면{"\n"}더 좋은 플레이송스를{"\n"}만나보세요
          </Mobile>
        </HomeFamilyInfoTextView>
      </HomeFamilyInfoVeriants>

      <HomeFamilyInfoVeriants2
        onClick={() => handleOpenNewTab("https://smartstore.naver.com/bicycle")}
      >
        <HomeFamilyInfoImageView2>
          <img
            src={
              "https://playsongshome.playsongs.ai/charlie/home/tablet/intro_store.png"
            }
            alt="character"
            width={"100%"}
          />
        </HomeFamilyInfoImageView2>
        <HomeFamilyInfoTextView2 className="text_wrap">
          찰리 친구들이라면{"\n"}
          플송 스토어
        </HomeFamilyInfoTextView2>
      </HomeFamilyInfoVeriants2>
    </>
  );
}

function StoreBanner() {
  return (
    <HomeFamilyInformationView>
      <HomeFamilyInfoVeriants
        onClick={() => handleOpenNewTab("http://www.playsongs.co.kr")}
      >
        <HomeFamilyInfoImageView>
          <img
            src="https://playsongshome.playsongs.ai/charlie/home/tablet/intro_playsongs.png"
            alt="character"
            width={"100%"}
          />
        </HomeFamilyInfoImageView>
        <HomeFamilyInfoTextView className="text_wrap">
          <Desktop>
            찰리와 함께하면{"\n"}더 좋은 플레이송스를{"\n"}만나보세요.
          </Desktop>
          <Tablet>찰리와 함께하면{"\n"}더 좋은 플레이송스를 만나보세요</Tablet>
          <Mobile>찰리와 함께하면{"\n"}더 좋은 플레이송스를 만나보세요</Mobile>
        </HomeFamilyInfoTextView>
      </HomeFamilyInfoVeriants>
      <HomeFamilyInfoVeriants
        onClick={() => handleOpenNewTab("https://smartstore.naver.com/bicycle")}
      >
        <HomeFamilyInfoImageView2>
          <img
            src={
              "https://playsongshome.playsongs.ai/charlie/home/intro_store.png"
            }
            alt="character"
            width={"100%"}
          />
        </HomeFamilyInfoImageView2>
        <HomeFamilyInfoTextView
          className="text_wrap"
          style={{ right: "3.5131%" }}
        >
          찰리 친구들이라면{"\n"}
          플송 스토어
        </HomeFamilyInfoTextView>
      </HomeFamilyInfoVeriants>
    </HomeFamilyInformationView>
  );
}
function AdmissionView() {
  return (
    <HomeAdmissionInformationView onClick={() => handleLink("/Admission")}>
      <HomeAdmissionInformationTextView>
        <HomeAdmissionInformationDescriptionView>
          If you have any questions about getting into the Charlie's Big Red
          House?
        </HomeAdmissionInformationDescriptionView>
        <HomeAdmissionInformationButtonView className="text_wrap">
          입학 상담안내{"  "} {"＞"}
        </HomeAdmissionInformationButtonView>
      </HomeAdmissionInformationTextView>
      <HomeAdmissionInfomationImageView>
        <img
          src="https://playsongshome.playsongs.ai/charlie/home/main_charlie.png"
          alt="character"
          width={"100%"}
        />
      </HomeAdmissionInfomationImageView>
    </HomeAdmissionInformationView>
  );
}

function DescriptionView() {
  const screenType = useScreenType();
  return (
    <>
      <HomeDescriptionView className="text_wrap">
        {StringManager.homePart1String[screenType]}
      </HomeDescriptionView>
      <HomeDescriptionKorView className="text_wrap">
        {StringManager.homePart1KorString[screenType]}
      </HomeDescriptionKorView>
    </>
  );
}

function ImageSectionView() {
  const screenType = useScreenType();
  return (
    <>
      <HomeImageZone>
        <HomeHiestoryView>
          <HomeIEYCTextView className="text_wrap">
            {StringManager.homePart2info1String[screenType]}
            <HomeIEYCTextSpan
              onClick={() =>
                handleOpenNewTab(
                  "https://playsongshome.playsongs.ai/charlie/IEYC.pdf"
                )
              }
            >
              {StringManager.homePart2info2String[screenType]}
            </HomeIEYCTextSpan>
            {StringManager.homePart2info3String[screenType]}
          </HomeIEYCTextView>
          {screenType === "pc" && (
            <HomeIEYCKorTextView className="text_wrap">
              <HomeIEYCKorTextSpan
                onClick={() =>
                  handleOpenNewTab(
                    "https://playsongshome.playsongs.ai/charlie/IEYC.pdf"
                  )
                }
              >
                {StringManager.homePart2info1KorString[screenType]}
              </HomeIEYCKorTextSpan>
              {StringManager.homePart2info2KorString[screenType]}
            </HomeIEYCKorTextView>
          )}
        </HomeHiestoryView>

        <HomeImageSection>
          <img
            src="https://playsongshome.playsongs.ai/logo/footer/ieyc-square-logo-with-text%403x.png"
            alt="ieyc"
            width={"100%"}
            onClick={() =>
              handleOpenNewTab(
                "https://playsongshome.playsongs.ai/charlie/IEYC.pdf"
              )
            }
          />
        </HomeImageSection>
      </HomeImageZone>
      {(screenType === "tablet" || screenType === "mobile") && (
        <HomeIEYCKorTextView className="text_wrap">
          <span>{StringManager.homePart2info1KorString[screenType]}</span>
          {StringManager.homePart2info2KorString[screenType]}
        </HomeIEYCKorTextView>
      )}
    </>
  );
}

export default Home;
