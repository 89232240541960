import styled from "styled-components";

export const BodyView = styled.div`
  @media (min-width: 1024px) {
    margin-left: 28.9063vw; //22.5521vw;
    margin-right: 9.375vw; //8.5938vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    width: 79.1667vw;
    margin: auto;
  }
  @media (max-width: 767px) {
    width: 92.5581vw;
    margin: auto;
  }
`;

export const PathStringView = styled.div`
  margin-top: 1.0417vw;
  margin-bottom: 0.7813vw;
`;

export const BannerView = styled.div`
  margin-top: 4.1667vw;
  clear: both;
  //width: 68.8542vw;
  width: 100%;
`;

export const HomeTitleView = styled.div`
  @media (min-width: 1024px) {
    margin-top: 10.4167vw;
    font-size: 1.875vw;
    line-height: 1.33;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    margin-top: 5.2083vw;
    font-size: 2.0833vw;
    line-height: 1.37;
    letter-spacing: -0.0417vw;
  }
  @media (max-width: 767px) {
    margin-top: 9.0698vw;
    font-size: 6.9767vw;
    line-height: 1.37;
    letter-spacing: -0.1395vw;
  }

  font-weight: 800;
  text-align: left;
  color: #f70000;
`;

export const HomeDescriptionView = styled.div`
  @media (min-width: 1024px) {
    margin-top: 0.9vw;
    font-size: 1.25vw;
    line-height: 1.5;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    margin-top: 1.5625vw;
    line-height: 1.44;
    font-size: 2.3438vw;
  }
  @media (max-width: 767px) {
    margin-top: 0.625vw;
    font-size: 4.186vw;
    line-height: 6.0465vw;
  }
  font-weight: 400;
  text-align: left;
  color: #222222;
`;

export const HomeDescriptionKorView = styled.div`
  @media (min-width: 1024px) {
    margin-top: 5.2083vw;
    line-height: 1.5;
    letter-spacing: -0.025vw;
    font-size: 1.0937vw;
    //font-size: 1.25vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    margin-top: 5.2083vw;
    line-height: 1.44;
    // font-size: 2.3438vw;
    font-size: 1.9532vw;
  }
  @media (max-width: 767px) {
    margin-top: 0.625vw;
    //font-size: 4.186vw;
    font-size: 3.4883vw;
    line-height: 6.0465vw;
  }
  font-family: Pretendard;
  font-weight: Regular;
  text-align: left;
  color: #222222;
`;

export const HomeImageZone = styled.div`
  display: flex;
  justify-content: space-between;
  @media (min-width: 1024px) {
    align-items: center;
    margin-top: 7.5521vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    align-items: flex-start;
    margin-top: 10.5469vw;
  }
  @media (max-width: 767px) {
    align-items: flex-start;
    margin-top: 13.9535vw;
  }
`;
export const HomeImageSection = styled.div`
  @media (min-width: 1024px) {
    margin-left: 0.5208vw;
    width: 11.875vw;
    position: relative;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    //margin-top: 13.0208vw;
    width: 15.8854vw;
    position: relative;
  }
  @media (max-width: 767px) {
    width: 28.3721vw;
    position: relative;
  }
    &:hover {
    cursor: pointer;
  }
`;

export const HomeHiestoryView = styled.div`
  @media (min-width: 1024px) {
    width: 51.3542vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    width: 62.5vw;
  }
  @media (max-width: 767px) {
    width: 62.3256vw;
  }

  // background-color:blue;
`;

export const HomeIEYCTextView = styled.div`
  @media (min-width: 1024px) {
    font-size: 1.25vw;
    line-height: 1.17;
    letter-spacing: -0.025vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    font-size: 2.3438vw;
    line-height: 1.44;
    letter-spacing: -0.0469vw;
  }
  @media (max-width: 767px) {
    font-size: 4.186vw;
    line-height: 1.44;
    letter-spacing: -0.0837vw;
  }

  font-weight: 400;
  text-align: left;
  color: #222222;

  a {
    text-decoration: underline;
    color: #f70000;
    &:hover {
      cursor: pointer;
    }
  }
`;

export const HomeIEYCTextSpan = styled.span`
  @media (min-width: 1024px) {
    font-size: 1.25vw;
    line-height: 1.17;
    letter-spacing: -0.025vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    font-size: 2.3438vw;
    line-height: 1.44;
    letter-spacing: -0.0469vw;
  }
  @media (max-width: 767px) {
    font-size: 4.186vw;
    line-height: 1.44;
    letter-spacing: -0.0837vw;
  }

  font-weight: 400;
  text-align: left;
  text-decoration: underline;
  color: #f70000;

  &:hover {
    cursor: pointer;
  }
`;

export const HomeIEYCKorTextView = styled.div`
  @media (min-width: 1024px) {
    margin-top: 1.0417vw;
    //font-size: 1.25vw;
    font-size: 1.0937vw;
    line-height: 1.5;
    letter-spacing: -0.025vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    margin-top: 2.6042vw;
    //font-size: 2.3438vw;
    font-size: 1.9532vw;
    line-height: 1.44;
    letter-spacing: -0.36px;
  }
  @media (max-width: 767px) {
    margin-top: 4.6512vw;
    //font-size: 4.186vw;
    font-size: 3.4883vw;
    line-height: 1.44;
    letter-spacing: -0.0837vw;
  }
  font-family: Pretendard;
  text-align: left;
  color: #222222;
  font-weight: regular;
  span {
    text-decoration: underline;
    color: #f70000;
    &:hover {
      cursor: pointer;
    }
  }
`;

export const HomeIEYCKorTextSpan = styled.span`
  @media (min-width: 1024px) {
    margin-top: 1.0417vw;
    // font-size: 1.25vw;
    font-size: 1.0937vw;
    line-height: 1.5;
    letter-spacing: -0.025vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    margin-top: 2.6042vw;
    //  font-size: 2.3438vw;
    font-size: 1.9532vw;
    line-height: 1.44;
    letter-spacing: -0.36px;
  }
  @media (max-width: 767px) {
    margin-top: 4.6512vw;
    //font-size: 4.186vw;
    font-size: 3.4883vw;
    line-height: 1.44;
    letter-spacing: -0.0837vw;
  }
  font-family: Pretendard;
  text-align: left;
  color: #222222;
  font-weight: regular;
  text-decoration: underline;
  color: #f70000;
  &:hover {
    cursor: pointer;
  }
`;

export const HomeAdmissionInformationView = styled.div`
  @media (min-width: 1024px) {
    margin-top: 10.4vw;
    //width: 68.8542vw;
    width: 100%;
    height: 14.5833vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    margin-top: 13.0208vw;
    width: 100%;
    height: 20.3125vw;
  }
  @media (max-width: 767px) {
    margin-top: 9.3vw;
    width: 100%;
    height: 36.2791vw;
  }

  clear: both;
  position: relative;
  display: flex;
  align-items: center;
  background-color: #f70000;
`;

export const HomeAdmissionInformationTextView = styled.div`
  @media (min-width: 1024px) {
    padding: 2.1vw 3.125vw 2vw 3.125vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    padding: 2.9948vw 2.9036vw 3.3203vw 2.3438vw;
  }
  @media (max-width: 767px) {
    padding: 4.186vw 4.186vw 4.186vw 3.125vw;
  }
  position: absolute;
`;

export const HomeAdmissionInformationDescriptionView = styled.div`
  @media (min-width: 1024px) {
    width: 45.7292vw;
    height: 6.3542vw;
    font-size: 2.2917vw;
    line-height: 2.8646vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    width: 61.849vw;
    font-size: 2.6042vw;
    line-height: 1.2;
  }
  @media (max-width: 767px) {
    width: 66.9767vw;
    font-size: 4.186vw;
    line-height: 1.33;
  }

  font-weight: 700;
  text-align: left;
  color: #ffffff;
`;

export const HomeAdmissionInformationButtonView = styled.div`
  @media (min-width: 1024px) {
    margin-top: 1.1vw;
    font-size: 2.0833vw;
    line-height: 2.6042vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    margin-top: 0.9302vw;
    font-size: 3.125vw;
    line-height: 2.08;
  }
  @media (max-width: 767px) {
    margin-top: 0.9302vw;
    font-size: 5.5814vw;
    line-height: 2.08;
  }
  font-family: Pretendard;
  font-weight: 800;
  text-align: left;
  color: #ffffff;
`;

export const HomeAdmissionInfomationImageView = styled.div`
  @media (min-width: 1024px) {
    width: 8.401vw;
    height: 11.7708vw;
    right: 2.7188vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    width: 9.987vw;
    height: 13.9974vw;
    right: 4.0233vw;
  }
  @media (max-width: 767px) {
    width: 17.907vw;
    height: 25.1163vw;
    right: 4.0233vw;
  }
  object-fit: contain;
  position: absolute;
`;

export const HomeFamilyInformationView = styled.div`
  clear: both;
  display: flex;
  align-items: center;

  @media (min-width: 1024px) {
    justify-content: space-between;
    margin-top: 10.4167vw;
    //width: 68.8542vw;
    width: 100%;
    height: 12.5vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
  }
  @media (max-width: 767px) {
    flex-direction: column;
    margin-top: 12.093vw;
    width: 92.6vw;

    position: relative;
  }
`;

export const HomeFamilyInfoVeriants = styled.div`
  clear: both;
  @media (min-width: 1024px) {
    //width: 33.9583vw;
    width: 49.3464%;
    height: 100%;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    margin-top: 13.0208vw;
    width: 100%;
    height: 15.625vw;
  }
  @media (max-width: 767px) {
    margin-top: 9.3023vw;
    width: 100%;
    height: 27.907vw;
  }
  background-color: #d5ecf6;

  display: flex;
  align-items: center;
  position: relative;
`;

export const HomeFamilyInfoVeriants2 = styled(HomeFamilyInfoVeriants)`
  @media (min-width: 768px) and (max-width: 1023px) {
    margin-top: 5.2083vw;
  }
  @media (max-width: 767px) {
    margin-top: 9.3vw;
  }
`;

export const HomeFamilyInfoImageView = styled.div`
  @media (min-width: 1024px) {
    width: 10.4531vw;
    height: 10.1875vw;
    //left: 4.7917vw;
    left: 4.7386%;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    width: 12.7214vw;
    height: 12.3958vw;
    left: 10.1563vw;
  }
  @media (max-width: 767px) {
    width: 22.7vw;
    height: 22.1vw;
    left: 10vw;
  }
  object-fit: contain;
  position: absolute;
`;

export const HomeFamilyInfoImageView2 = styled(HomeFamilyInfoImageView)`
  @media (min-width: 1024px) {
    width: 13.1771vw;
    height: 9.4583vw;
    //left: 3.2292vw;
    left: 3.5131%;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    left: 8.0729vw;
    width: 16.9792vw;
    height: 12.1875vw;
  }
  @media (max-width: 767px) {
    width: 30.3256vw;
    height: 21.7674vw;
  }
`;

export const HomeFamilyInfoTextView = styled.div`
  position: absolute;

  @media (min-width: 1024px) {
    right: 2.2917vw;
    //right: 3.5131%;
    width: 12.9688vw;
    font-size: 1.5625vw;

    line-height: 2.3438vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    font-size: 3.125vw;
    line-height: 1.5;
    right: 7.5521vw;
  }

  @media (max-width: 767px) {
    right: 4.6512vw;
    font-size: 5.1163vw;
    line-height: 1.36;
  }

  font-family: Pretendard;
  font-weight: bold;
  text-align: left;
  color: #222222;
`;
export const HomeFamilyInfoTextView2 = styled(HomeFamilyInfoTextView)`
  @media (min-width: 1024px) {
    right: 2.2917vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    right: 25.7813vw;
  }
  @media (max-width: 767px) {
    right: 10vw;
  }
`;
////// AboutUs

export const AUSectionView = styled.div`
  margin-top: 5.2083vw;
  height: 40.6771vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const AUImageView = styled.div`
  @media (min-width: 1024px) {
    width: 33.9583vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    width: 100%;
    height: 53.6458vw;
  }
  @media (max-width: 767px) {
    width: 100vw;
    height: 90.2326vw;
  }
`;

export const AUTextView = styled.div`
  margin-left: 0.625vw;
  width: 33.3854vw;
`;

export const AUTitleView = styled.div`
  width: 100%;
  @media (min-width: 1024px) {
    font-size: 1.875vw;
    line-height: 1.33;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    margin-top: 5.2083vw;
    font-size: 3.125vw;
    line-height: 1.33;
  }
  @media (max-width: 767px) {
    margin-top: 3.7209vw;
    font-size: 6.9767vw;
    line-height: 1.5;
  }

  font-weight: 800;
  text-align: left;
  color: red;
`;

export const AUDescriptionView = styled.div`
  @media (min-width: 1024px) {
    margin-top: 0.625vw;
    font-size: 1.25vw;
    line-height: 1.5vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    margin-top: 1.5625vw;
    font-size: 2.3438vw;
    line-height: 1.44;
  }
  @media (max-width: 767px) {
    margin-top: 1.8605vw;
    font-size: 4.186vw;
    line-height: 1.56;
  }
  text-align: left;
  color: #222222;
`;
export const AUMVTitleView = styled.div`
  @media (min-width: 1024px) {
    margin-top: 5.0521vw;
    font-size: 1.875vw;
    line-height: 1.33;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    margin-top: 13.0208vw;
    font-size: 3.125vw;
    line-height: 1.33;
    letter-spacing: -0.0625vw;
  }
  @media (max-width: 767px) {
    margin-top: 9.3023vw;
    font-size: 6.9767vw;
    line-height: 1.5;
  }

  font-weight: 800;
  text-align: left;
  color: red;
  //text-transform: uppercase;
`;
export const AUMVTitle2View = styled(AUMVTitleView)`
  @media (min-width: 1024px) {
    margin-top: 2.0833vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    margin-top: 5.2083vw;
  }
  @media (max-width: 767px) {
    margin-top: 6.2791vw;
  }
`;
export const AUMVDescriptionView0 = styled.div`
  @media (min-width: 1024px) {
    margin-top: 0.625vw;
    //font-size: 1.25vw;
    font-size: 1.0937vw;
    line-height: 1.5;
    letter-spacing: -0.025vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    margin-top: 3.125vw;
    //font-size: 2.3438vw;
    font-size: 1.9532vw;
    line-height: 1.56;
  }
  @media (max-width: 767px) {
    margin-top: 1.8605vw;
    //font-size: 4.186vw;
    font-size: 3.4883vw;
    line-height: 1.44;
  }

  font-family: Pretendard;
  font-weight: 600;
  text-align: left;
  color: #222;
  // text-transform: uppercase;
`;
export const AUMVImgView = styled.div`
  @media (min-width: 1024px) {
    margin-top: 5.2083vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    margin-top: 7.8125vw;
  }
  @media (max-width: 767px) {
    margin-top: 9.3023vw;
  }
  width: 100%;
  object-fit: contain;
`;

export const AUMVDescriptionView = styled.div<{ $first: boolean }>`
  @media (min-width: 1024px) {
    margin-top: ${({ $first }) => ($first ? "0.625vw" : "0.4167vw")};
    font-size: 1.25vw;
    line-height: 1.5;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    margin-top: 1.5625vw;
    font-size: 3.125vw;
    line-height: 1.33;
    letter-spacing: -0.0625vw;
  }
  @media (max-width: 767px) {
    margin-top: 1.8605vw;
    font-size: 4.186vw;
    line-height: 1.44;
  }
  font-weight: 600;
  text-align: left;
  color: #222;
`;

export const AUMVDescriptionView2 = styled.div`
  @media (min-width: 1024px) {
    margin-top: 0;
    //font-size: 1.25vw;
    font-size: 1.0937vw;
    line-height: 1.5;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    margin-top: 0.5208vw;
    //font-size: 2.3438vw;
    font-size: 1.9532vw;
    line-height: 1.56;
  }
  @media (max-width: 767px) {
    margin-top: 0.9302vw;
    //font-size: 4.186vw;
    font-size: 3.4883vw;
    line-height: 1.56;
  }
  font-weight: 600;
  text-align: left;
  color: #222;

  font-family: Pretendard;
`;

export const AUMVDescriptionEView = styled.div`
  @media (min-width: 1024px) {
    margin-top: 0.625vw;
    font-size: 1.25vw;
    line-height: 1.17;
    letter-spacing: -0.025vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    margin-top: 1.5625vw;
    font-size: 2.3438vw;
    line-height: 1.56;
  }
  @media (max-width: 767px) {
    margin-top: 2.7907vw;
    font-size: 4.186vw;
    line-height: 1.44;
  }
  font-weight: 600;
  text-align: left;
  color: #222;
`;

/// learning

export const LearningSectionView = styled.div`
  @media (min-width: 1024px) {
    margin-top: 5.2083vw;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

export const LearningImageView = styled.div`
  @media (min-width: 1024px) {
    margin-top: 5.3646vw;
    width: 100%;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    width: 79.1667vw;
    margin-top: 7.8125vw;
  }
  @media (max-width: 767px) {
    width: 100vw;
    margin-top: 6.0465vw;
  }
`;
export const LearningFontTextView = styled.span`
  @media (max-width: 767px) {
    margin-top: 3.7209vw;
    // font-size: 4.186vw;
    font-size: 3.4883vw;
    line-height: 1.56;
    letter-spacing: -0.0837vw;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    margin-top: 1.5625vw;
    // font-size: 2.3438vw;
    font-size: 1.9532vw;
    line-height: 1.44;
    letter-spacing: -0.0469vw;
  }
  @media (min-width: 1024px) {
    //font-size: 1.25vw;
    font-size: 1.0937vw;
    line-height: 1.5;
    letter-spacing: -0.025vw;
  }

  span {
    font-weight: bold;
  }
  font-family: Pretendard;
  color: #222;
  font-weight: regular;
`;
export const LearningTextView = styled.div`
  @media (min-width: 1024px) {
    margin-top: 1.1979vw;
    width: 100%;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    margin-top: 1.5625vw;
  }
  @media (max-width: 767px) {
    width: 100%;
  }
`;

export const LearningTitleView = styled.div`
  @media (min-width: 1024px) {
    font-size: 1.875vw;
    line-height: 1.33;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    margin-top: 2.6042vw;
    font-size: 3.6458vw;
    line-height: 1.36;
    letter-spacing: -0.0729vw;
  }
  @media (max-width: 767px) {
    margin-top: 2.7907vw;
    width: 100%;
    font-size: 6.9767vw;
    line-height: 1.37;
    letter-spacing: -0.1395vw;
  }
  font-weight: 800;
  text-align: left;
  color: red;
  border-bottom: solid 2px red;
`;

export const LearningDescriptionView = styled.div`
  @media (min-width: 1024px) {
    margin-top: 1.1979vw;
    font-size: 1.25vw;
    line-height: 1.67;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    margin-top: 1.5625vw;
    font-size: 2.3438vw;
    line-height: 1.56;
  }
  @media (max-width: 767px) {
    margin-top: 2.7907vw;
    font-size: 4.186vw;
    line-height: 1.56;
    color: #707070;
  }

  span {
    font-weight: bold;
  }
  font-weight: 400;
  color: #222222;
`;
export const LearningDescriptionKView = styled.div`
  font-family: Pretendard;

  @media (min-width: 1024px) {
    margin-top: 1.25vw;
    // font-size: 1.25vw;
    font-size: 1.0937vw;
    line-height: 1.5;
    letter-spacing: -0.025vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    margin-top: 2.6042vw;
    //font-size: 2.3438vw;
    font-size: 1.9532vw;
    line-height: 1.56;
    letter-spacing: -0.0469vw;
  }
  @media (max-width: 767px) {
    margin-top: 2.7907vw;
    // font-size: 4.186vw;
    font-size: 3.4883vw;
    line-height: 1.56;
    letter-spacing: -0.0837vw;
    color: #707070;
  }
  span {
    font-weight: bold;
  }
  color: #222222;
`;
export const LearningPageTitleView = styled.div`
  @media (min-width: 1024px) {
    margin-top: 1.25vw;
    font-size: 1.875vw;
    line-height: 1.33;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    margin-top: 1.5625vw;
    font-size: 3.6458vw;
    line-height: 1.36;
    letter-spacing: -0.0729vw;
    border-bottom: solid 0.2604vw red;
  }
  @media (max-width: 767px) {
    margin-top: 3.7209vw;
    font-size: 6.9767vw;
    line-height: 9.5349vw;
    letter-spacing: -0.1395vw;
    border-bottom: solid 0.4651vw red;
  }
  font-weight: 800;
  text-align: left;
  color: red;
  text-transform: uppercase;
`;
export const LearningPageInfoView = styled.div`
  @media (min-width: 1024px) {
    margin-top: 0.625vw;
    width: 100%;
  }

  display: flex;
  justify-content: flex-start;
`;

export const LearningPageInfoDescriptionView = styled.div`
  width: 40.9375vw;
`;

export const LearningPageInfoTextView = styled.div`
  @media (min-width: 1024px) {
    font-size: 1.25vw;
    line-height: 2.0833vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    margin-top: 1.5625vw;
    font-size: 2.3438vw;
    line-height: 1.56;
  }
  @media (max-width: 767px) {
    margin-top: 1.1628vw;
    font-size: 4.186vw;
    line-height: 6.5116vw;
  }
  width: 100%;
  text-align: left;
  color: #222222;
`;

export const LearningPageInfoText2View = styled(LearningPageInfoTextView)`
  @media (min-width: 1024px) {
    margin-top: 1.25vw;
    line-height: 1.5;
    letter-spacing: -0.025vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    margin-top: 1.5625vw;
    font-size: 2.3438vw;
    line-height: 1.56;
  }
  @media (max-width: 767px) {
    font-size: 4.186vw;
    line-height: 1.56;
    letter-spacing: -0.0837vw;
  }
  font-family: Pretendard;
`;

export const LearningPageInfoImgView = styled.div`
  @media (min-width: 1024px) {
    margin-top: 1.25vw;
    width: 40.9375vw;
    height: 26.4583vw;
    object-fit: contain;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    margin-top: 7.8125vw;
    width: 100%;
    //background-color:blue;
  }
  @media (max-width: 767px) {
    margin-top: 5.5814vw;
    width: 100%;
  }
`;
export const LearningPageInfoTextView2 = styled(LearningPageInfoTextView)`
  @media (min-width: 1024px) {
    margin-top: 1.25vw;
  }
`;
export const LearningPageInfoLinkSectionView = styled.div`
  @media (min-width: 1024px) {
    clear: both;
    position: relative;
    top: 0;
    //right: 0;
    margin-left: 3.2604vw;
    width: 20.125vw;
    //background-color: pink;
  }
`;
export const LearningPageInfoLinkView = styled.div`
  @media (min-width: 1024px) {
    position: absolute;
    //top: 10.1042vw; //1.3646vw;
    top: 1.3646vw;
  }
`;
export const LearningPageInfoLinkTextView = styled.div`
  @media (min-width: 1024px) {
    margin-top: 1.6146vw;
    font-size: 1.1458vw;
    line-height: 1.33;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    margin-top: 5.2083vw;
    font-size: 3.125vw;
    line-height: 1.33;
  }
  @media (max-width: 767px) {
    margin-top: 9.3023vw;
    font-size: 5.5814vw;
    line-height: 1.33;
  }

  text-transform: uppercase;
  font-weight: 700;
  text-align: left;
  color: red;
  &:hover {
    cursor: pointer;
  }
`;

export const LearningPageInfoLinkText2View = styled(
  LearningPageInfoLinkTextView
)`
  margin-top: 1.0938vw;
`;

/// Admission
export const AdmissionPageTitleView = styled.div`
  @media (min-width: 1024px) {
    margin-top: 2.0833vw;
    font-size: 1.5625vw;
    line-height: 1.37;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    margin-top: 7.8125vw;
    font-size: 3.6458vw;
    line-height: 1.36;
    letter-spacing: -0.0729vw;
    border-bottom: solid 0.2604vw red;
  }
  @media (max-width: 767px) {
    margin-top: 14.8837vw;
    font-size: 6.9767vw;
    line-height: 9.5349vw;
    letter-spacing: -0.1395vw;
    border-bottom: solid 0.4651vw red;
  }
  font-weight: 800;
  text-align: left;
  color: red;
  text-transform: uppercase;
`;

export const AdmissionSectionView = styled.div`
  display: flex;
  align-items: center;
  @media (min-width: 1024px) {
    justify-content: space-between;
    margin-top: 1.25vw;
    //width: 63.6458vw;
    width: 100%;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    justify-content: space-between;
    margin-top: 2.6042vw;
  }
`;
export const AdmissionButtonView = styled.div`
  @media (min-width: 1024px) {
    //width: 31.6667vw;
    width: 49.1803%;
    height: 4.0625vw;
    border: solid 0.0521vw #222222;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    width: 38.5417vw;
    height: 8.3333vw;
    border: solid 0.1302vw #222;
  }
  @media (max-width: 767px) {
    margin-top: 9.3023vw;
    width: 92.5581vw;
    height: 14.8837vw;
    border: solid 0.2326vw #222222;
  }

  span {
    @media (min-width: 1024px) {
      -webkit-text-stroke: 0.0521vw #707070;
      font-size: 1.25vw;
      line-height: 1.36;
      text-transform: uppercase;
    }
    @media (min-width: 768px) and (max-width: 1023px) {
      font-size: 2.8646vw;
      line-height: 1.32;
    }
    @media (max-width: 767px) {
      font-size: 5.1163vw;
      line-height: 1.32;
    }
    font-weight: 800;
    color: #222;

    &:hover {
      color: #f70000;
      -webkit-text-stroke: 0.0521vw #f70000;
      cursor: pointer;
    }
  }
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #ffffff;
`;
export const AdmissionCampusSectionView = styled.div`
  //display: flex;
  //align-items: center;
  //justify-content: space-between;
  width: 100%;
  @media (min-width: 1024px) {
    margin-top: 5.2083vw;

    // width: 27.4479vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    padding-top: 4.1667vw;
    // width: 48.4375vw;
  }
  @media (max-width: 767px) {
    padding-top: 9.3023vw;
    width: 91.6279vw;
  }
`;
export const AdmissionCampusLinkView = styled.div`
  @media (min-width: 1024px) {
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    font-size: 2.6042vw;
    line-height: 1.35;
  }
  @media (max-width: 767px) {
    font-size: 5.1163vw;
    line-height: 1.32;
  }
  font-weight: bold;
  color: #9e9e9e;
`;
//// Contact us

export const ContactUSMapView = styled.div`
  @media (min-width: 1024px) {
    margin-top: 1.25vw;
    clear: both;
    //width: 68.8542vw;
    width: 100%;
    height: 38.5938vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    margin-top: 2.6042vw;
    width: 79.1667vw;
    height: 43.2292vw;
  }
  @media (max-width: 767px) {
    margin-top: 1.8605vw;
    width: 92.8372vw;
    height: 52.093vw;
  }
`;
export const ContactUsThanksTextView = styled.div`
  @media (min-width: 1024px) {
    margin-top: 1.25vw;
    line-height: 1.2;
    font-size: 1.5625vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    margin-top: 1.5625vw;
    font-size: 2.3438vw;
    line-height: 1.56;
  }
  @media (max-width: 767px) {
  }

  font-weight: bold;
  text-align: left;
  color: #222222;
`;

export const ContactUsTitleView = styled(LearningPageTitleView)`
  @media (min-width: 1024px) {
    margin-top: 0;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    margin-top: 2.6042vw;
    border-bottom: 0;
  }
  @media (max-width: 767px) {
    margin-top: 3.7209vw;
    border-bottom: 0;
  }
`;

export const ContactUsSectionView = styled.div`
  @media (min-width: 1024px) {
    margin-top: 7.8125vw;
    width: 100%;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    margin-top: 7.8125vw;
  }
  @media (max-width: 767px) {
    margin-top: 18.6047vw;
  }
`;

export const ContactUsInfoView = styled(LearningPageInfoView)`
  @media (min-width: 1024px) {
    margin-top: 2.2396vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    margin-top: 2.6042vw;
  }
  @media (max-width: 767px) {
    margin-top: 5.5814vw;
  }
`;
export const ContactUsInfoAddressView = styled.div`
  @media (min-width: 1024px) {
    width: 25.3125vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    width: 40.3646vw;
  }
  @media (max-width: 767px) {
    width: 43.7209vw;
  }

  //background-color:blue;
`;
export const ContactUsInfoPhoneView = styled.div`
  @media (min-width: 1024px) {
    margin-left: 2.6042vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    margin-left: 3.6458vw;
  }
  @media (max-width: 767px) {
    margin-left: 2.3256vw;
  }
  //background-color:yellow;
`;
export const ContactUsInfoIconView = styled.div`
  @media (min-width: 1024px) {
    height: 2.3073vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    height: 3.776vw;
  }
  @media (max-width: 767px) {
    height: 6.9767vw;
  }
`;
export const ContactUsInfoText1View = styled.div`
  @media (min-width: 1024px) {
    width: 8.0729vw;
    height: 2.8125vw;
    font-size: 1.875vw;
    line-height: 1.875vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    font-size: 2.8646vw;
    line-height: 1.64;
  }
  @media (max-width: 767px) {
    width: 36.0465vw;
    height: 8.1395vw;
    font-size: 5.1163vw;
    line-height: 8.3721vw;
  }

  font-weight: 600;
  text-align: left;
  color: black;
`;

export const ContactUsInfoText3View = styled(LearningPageInfoText2View)`
  @media (min-width: 1024px) {
    margin-top: 0;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    margin-top: 1.0417vw;
  }
  @media (max-width: 767px) {
  }
`;

// family site
export const FamilySiteZone = styled.div`
  margin: 0 auto;
  @media (min-width: 1024px) {
    width: 66.6667vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    width: 79.1667vw;
  }
  @media (max-width: 767px) {
    width: 85.5814vw;
  }
`;

export const FamilySitesection = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 1024px) {
    margin-top: 3.125vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    margin-top: 5.2083vw;
  }
  @media (max-width: 767px) {
    margin-top: 9.3023vw;
  }
`;

export const FamilySiteInstaLine = styled.div`
  @media (max-width: 767px) {
    display: flex;
    align-items: end;
    margin-top: 3.7209vw;

    img {
      &:hover {
        cursor: pointer;
      }
    }
  }
`;
export const FamilySiteLogoLineBycycle = styled.div`
  display: flex;
  align-items: end;

  span {
    margin-left: 1.0417vw;
    font-size: 1.5625vw;
    font-weight: bold;
    line-height: 1;
    letter-spacing: -0.0313vw;
    text-align: left;
    color: #000;
    font-family: Pretendard;

    @media (min-width: 768px) and (max-width: 1023px) {
      margin-left: 2.6042vw;
      font-size: 3.9063vw;
      letter-spacing: -0.0781vw;
    }
    @media (max-width: 767px) {
      margin-left: 7.907vw;
      font-size: 5.5814vw;
      line-height: 1.25;
      letter-spacing: -0.1116vw;
    }
  }
  img {
    @media (min-width: 1024px) {
      width: 16.1458vw;
      height: 3.0208vw;
    }

    @media (min-width: 768px) and (max-width: 1023px) {
      width: 32.0313vw;
      height: 5.9896vw;
    }

    @media (max-width: 767px) {
      width: 40.4651vw;
      height: 7.4419vw;
    }
    &:hover {
      cursor: pointer;
    }
  }
`;

export const FamilySiteInstaImg = styled.img`
  @media (min-width: 1024px) {
    margin-left: 0.625vw;
    width: 2.0833vw;
    height: 2.0833vw;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    margin-left: 1.5625vw;
    width: 5.2083vw;
    height: 5.2083vw;
  }

  @media (max-width: 767px) {
    margin-left: 1.8605vw;
    width: 9.3023vw;
    height: 9.3023vw;
  }
`;

export const FamilySiteLogoLineSection = styled.div`
  display: flex;
  align-items: end;
`;

export const FamilySiteLogoLine = styled.div`
  position: relative;
`;
export const FamilySiteLogoLineArrow = styled.div`
  position: absolute;

  @media (min-width: 1024px) {
    right: -1.0416vw;
    top: -0.8333vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    right: -3.3854vw;
    //top: -2.0833vw;
    top: 0vw;
  }
  @media (max-width: 767px) {
    top: 0;
    right: -5.5814vw;
  }

  img {
    width: 3.7209vw; /* 이미지를 컨테이너에 맞게 확장합니다. */
    height: 3.7209vw;

    @media (min-width: 1024px) {
      width: 0.8333vw;
      height: 0.8333vw;
    }
    @media (min-width: 768px) and (max-width: 1023px) {
      width: 2.0833vw;
      height: 2.0833vw;
    }
  }
  // background-color: red;
`;

export const FamilySiteLogoLinePImg = styled.img`
  @media (min-width: 1024px) {
    width: 8.5938vw;
    height: 4.2188vw;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    width: 18.8802vw;
    height: 9.2448vw;
  }

  @media (max-width: 767px) {
    width: 26.7442vw;
    height: 13.2558vw;
  }
  &:hover {
    cursor: pointer;
  }
`;
export const FamilySiteLogoLinePH = styled.div`
  display: flex;
  align-items: end;

  span {
    margin-left: 1.0417vw;
    font-size: 1.5625vw;
    font-weight: bold;
    line-height: 1;
    letter-spacing: -0.0313vw;
    text-align: left;
    color: #000;
    font-family: Pretendard;

    @media (min-width: 768px) and (max-width: 1023px) {
      margin-left: 4.1667vw; //2.6042vw;
      font-size: 3.9063vw;
      letter-spacing: -0.0781vw;
    }
    @media (max-width: 767px) {
      margin-left: 7.907vw; //3.7209vw;
      font-size: 5.5814vw;
      line-height: 1.25;
      letter-spacing: -0.1116vw;
    }
  }
`;
export const FamilySiteLogoLinePHImg = styled.img`
  @media (min-width: 1024px) {
    width: 5.7813vw;
    height: 5.5729vw;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    width: 14.4531vw;
    height: 13.9323vw;
  }

  @media (max-width: 767px) {
    width: 19.3023vw;
    height: 18.8372vw;
  }
  &:hover {
    cursor: pointer;
  }
`;
export const FamilySiteLogoLineC = styled.div`
  display: flex;
  align-items: end;

  span {
    margin-left: 1.0417vw;
    font-size: 1.5625vw;
    font-weight: bold;
    line-height: 1;
    letter-spacing: -0.0313vw;
    text-align: left;
    color: #000;
    font-family: Pretendard;

    @media (min-width: 768px) and (max-width: 1023px) {
      margin-left: 2.6042vw;
      font-size: 3.9063vw;
      letter-spacing: -0.0781vw;
    }
    @media (max-width: 767px) {
      margin-left: 7.907vw; //3.7209vw;
      font-size: 5.5814vw;
      line-height: 1.25;
      letter-spacing: -0.1116vw;
    }
  }
`;

export const FamilySiteLogoLineCImg = styled.img`
  @media (min-width: 1024px) {
    width: 5.625vw;
    height: 5.2604vw;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    width: 14.0625vw;
    height: 13.151vw;
  }

  @media (max-width: 767px) {
    width: 20vw;
    height: 19.3023vw;
  }
  &:hover {
    cursor: pointer;
  }
`;
export const FamilySiteInsta = styled.div`
  span {
    margin-left: 0.2083vw;
    font-size: 0.9375vw;
    line-height: 1.94;
    letter-spacing: -0.0469vw;
    text-align: left;
    font-weight: normal;
    font-family: Pretendard;

    color: #000;
    @media (min-width: 768px) and (max-width: 1023px) {
      margin-left: 0.5208vw;
      font-size: 2.3438vw;
      letter-spacing: -0.1172vw;
    }
    @media (max-width: 767px) {
      font-size: 4.186vw;
      letter-spacing: -0.2093vw;
    }
  }
  img {
    @media (min-width: 1024px) {
      margin-left: 0.625vw;
      width: 2.0833vw;
      height: 2.0833vw;
    }

    @media (min-width: 768px) and (max-width: 1023px) {
      margin-left: 1.5625vw;
      width: 5.2083vw;
      height: 5.2083vw;
    }

    @media (max-width: 767px) {
      margin-left: 1.8605vw;
      width: 9.3023vw;
      height: 9.3023vw;
    }
    &:hover {
      cursor: pointer;
    }
  }
  &:hover {
    cursor: default;
  }
`;
export const FamilySiteDescription = styled.span`
  margin-top: 1.0417vw;
  font-size: 1.0417vw;
  line-height: 1.75;
  letter-spacing: -0.0521vw;
  text-align: left;
  color: #000;
  font-family: Pretendard;
  @media (min-width: 768px) and (max-width: 1023px) {
    margin-top: 3.3854vw;
    font-size: 2.6042vw;
    letter-spacing: -0.1302vw;
  }
  @media (max-width: 767px) {
    margin-top: 3.7209vw;
    font-size: 4.6512vw;
    letter-spacing: -0.2326vw;
  }
`;

// App Service
export const ASTitle = styled.div`
  margin-top: 3.125vw;
  font-family: Pretendard;
  font-size: 1.5625vw;
  font-weight: 900;
  line-height: 1.2;
  text-align: center;
  color: #222222;
  @media (min-width: 768px) and (max-width: 1023px) {
    margin-top: 5.2083vw;
    font-size: 3.9063vw;
  }
  @media (max-width: 767px) {
    margin-top: 9.3023vw;
    font-size: 5.5814vw;
  }
`;

export const ASSection = styled.div`
  display: flex;

  @media (min-width: 1024px) {
    margin-top: 3.125vw;
    justify-content: space-between;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    margin-top: 5.2083vw;
    align-items: end;
  }
  @media (max-width: 767px) {
    margin-top: 10.9302vw;
    align-items: end;
  }
`;
export const ASSection2 = styled(ASSection)`
  margin-top: 5.8854vw;

  @media (max-width: 767px) {
    margin-top: 9.3023vw;
  }
`;
export const ASSectionTextZone = styled.div``;
export const ASSectionTitle = styled.div`
  font-family: Pretendard;
  font-size: 1.5625vw;
  font-weight: bold;
  line-height: 1.07;
  color: #222222;
  @media (min-width: 768px) and (max-width: 1023px) {
    font-size: 3.9063vw;
    margin-left: 4.1667vw;
  }
  @media (max-width: 767px) {
    font-size: 6.9767vw;
    margin-left: 7.4419vw;
  }
`;
export const ASSectionDescription = styled.div`
  margin-top: 1.0417vw;
  font-family: Pretendard;
  font-size: 1.0417vw;

  line-height: 1.6;
  color: #222222;

  @media (min-width: 768px) and (max-width: 1023px) {
    font-size: 2.6042vw;
    letter-spacing: -0.0781vw;
    margin-top: 2.6042vw;
  }
  @media (max-width: 767px) {
    margin-top: 4.6512vw;
    font-size: 4.6512vw;
    letter-spacing: -0.1395vw;
  }
`;
export const ASSectionImage = styled.img`
  width: 11.5625vw;
  object-fit: contain;
  @media (min-width: 768px) and (max-width: 1023px) {
    width: 13.0208vw;
  }
  @media (max-width: 767px) {
    width: 23.2558vw;
  }
`;
export const ASSectionDownLoadZone = styled.div`
  margin-top: 1.0417vw;
  display: flex;
  align-items: center;
  @media (min-width: 768px) and (max-width: 1023px) {
    margin-top: 2.6042vw;
  }
  @media (max-width: 767px) {
    margin-top: 4.6512vw;
  }
`;
export const ASSectionDownLoadTitle = styled.div`
  font-family: Pretendard;
  font-size: 1.25vw;
  font-weight: bold;
  line-height: 1.33;
  text-align: left;
  color: #222222;
  @media (min-width: 768px) and (max-width: 1023px) {
    font-size: 3.125vw;
  }
  @media (max-width: 767px) {
    font-size: 5.5814vw;
  }
`;
export const ASSectionDownLoadArrowImage = styled.img`
  width: 2.6042vw;
  height: 0.8333vw;
  margin-left: 0.8073vw;
  object-fit: contain;
  @media (min-width: 768px) and (max-width: 1023px) {
    width: 6.5104vw;
    height: 2.0833vw;
    margin-left: 2.0833vw;
  }
  @media (max-width: 767px) {
    width: 11.6279vw;
    height: 3.7209vw;
    margin-left: 3.7209vw;
  }
`;

export const ASSectionDownLoadIconImage = styled.img`
  margin-left: 1.0677vw;
  height: 1.7708vw;
  object-fit: contain;
  &:hover {
    cursor: pointer;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    height: 4.4271vw;
    margin-left: 2.6042vw;
  }
  @media (max-width: 767px) {
    margin-left: 4.6512vw;
    height: 7.907vw;
  }
`;
export const ASSectionDownLoadIconImage2 = styled(ASSectionDownLoadIconImage)`
  margin-left: 0.8333vw;
  @media (min-width: 768px) and (max-width: 1023px) {
    margin-left: 2.0833vw;
  }
  @media (max-width: 767px) {
    margin-left: 3.7209vw;
  }
`;
/// footer

export const Separatorline = styled.div`
  border: solid 0.026vw #707070;
  @media (min-width: 1024px) {
    width: 68.8542vw;
  }
  @media (max-width: 1023px) {
    width: 100%;
  }
`;

/// workwithus
export const WWUTitleView = styled(LearningPageTitleView)`
  @media (min-width: 1024px) {
    margin-top: 2.0833vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    margin-top: 1.5625vw;
    border-bottom: 0;
  }
  @media (max-width: 767px) {
    margin-top: 3.7209vw;
    border-bottom: 0;
  }
`;

export const WWUDescriptionSection = styled.div`
  @media (min-width: 1024px) {
    margin-right: 18.6979vw;
  }
`;

export const WWUDescription = styled(LearningPageInfoTextView)`
  @media (min-width: 1024px) {
    margin-top: 0.625vw;
    line-height: 1.67;
    font-size: 1.25vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    margin-top: 1.0417vw;
    border-bottom: 0;
  }
  @media (max-width: 767px) {
    font-size: 5.1163vw;
    font-weight: 600;
    line-height: 1.64;
    margin-top: 3.7209vw;
    border-bottom: 0;
  }
`;

export const WWUDescription2 = styled(WWUDescription)`
  @media (min-width: 1024px) {
    margin-top: 2.0833vw;
    font-weight: bold;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    margin-top: 5.2083vw;
    font-weight: bold;
  }
  @media (max-width: 767px) {
    margin-top: 9.3023vw;
    font-weight: 600;
  }
`;

export const WWUDescription3 = styled(WWUDescription)`
  @media (max-width: 767px) {
    font-weight: normal;
  }
`;
///////////////////////////////////////

export const MobileInfoLinkTextView = styled.div`
  margin-top: 9.3023vw;
  width: 77.6744vw;
  height: 8.3721vw;
  font-size: 5.5814vw;
  font-weight: 800;
  line-height: 7.4419vw;
  font-family: Ysabeau SC;
  text-align: left;
  color: red;
  &:hover {
    cursor: pointer;
  }
`;

export const handleOpenNewTab = (url: string | URL | undefined) => {
  if (!Boolean(url)) return;

  console.log("new Window");
  window.open(url, "_blank", "noopener, noreferrer");
};

export const handleLink = (url: string) => {
  //window.location.replace(url);
  window.location.href = url;
};
